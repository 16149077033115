import ArrowLeftOutlinedIcon from '@mui/icons-material/ArrowLeftOutlined';
import { LoadingButton } from '@mui/lab';
import { Divider, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getEmail, getEmailLowerCase, login } from '../../../../api/api';
import InputTextController from '../../../../components/Atoms/InputTextController';
import CircularProgressCustom from '../../../../components/CircularProgressCustom/CircularProgressCustom';
import DevelopedBySection from '../../../../components/LayoutAuth/DevelopedBySection';
import HeaderLogo from '../../../../components/LayoutAuth/HeaderLogo';
import HelpSection from '../../../../components/LayoutAuth/HelpSection';
import { LOGIN } from '../../../../constants/routes';
import BusinessUnitParamsContext from '../../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { setValidateAccountParameters } from '../../../../redux/actions/validateAccountActions';
import { getValidateAccountSelector } from '../../../../redux/selectors';
import { useStyles as useStylesLogin } from '../../../../styles/global/stylesLogin';
import { isEmpty, maskEmail } from '../../../../util/commons';
import { setSessionInfo } from '../../../../util/LocalStorage/login';
import { useStyles } from './styles';

const ValidateTemporalPasswordForm = () => {
  const classes = useStyles();
  const classesLogin = useStylesLogin();
  const history = useHistory();
  const { email, tokenExpirationTime } = useSelector(getValidateAccountSelector);
  const [showPassword, setShowPassword] = useState(false);
  const [emailToShow, setEmailToShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(true);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { businessUnitUUID, portalConfigParams } = useContext(BusinessUnitParamsContext);
  const encodedSearch = location.search.replace(/\+/g, '%2B');
  const queryParams = new URLSearchParams(encodedSearch);
  const paramEmail = queryParams.get('email');
  const dispatch = useDispatch();

  type FormInputs = {
    password: string;
  };
  const { control, handleSubmit, watch } = useForm<FormInputs>({
    mode: 'all',
  });
  const watchAllFields = watch();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data: FormInputs) => {
    setLoadingBtn(true);
    if (!executeRecaptcha) {
      setLoadingBtn(false);
      return console.info('Execute recaptcha not yet available');
    }
    const captcha = await executeRecaptcha('submit');
    await login(captcha, businessUnitUUID, '' + email, data.password)
      .then((response) => {
        if (!isEmpty(response.token)) {
          setSessionInfo(response, businessUnitUUID);
          setTimeout(() => {
            setLoadingBtn(false);
            location.reload();
          }, 1000);
        }
      })
      .catch(() => {
        setLoadingBtn(false);
        onError();
      });
  };

  const onError = () => {
    if (watchAllFields.password == '' || watchAllFields.password == undefined) {
      setAlertMessage('La contraseña es requerida');
      return;
    }
    setAlertMessage(
      'No pudimos validar los datos ingresados. Verifica la información o comunícate con soporte.'
    );
  };
  const goToLogin = () => {
    history.push({ pathname: LOGIN });
  };

  useEffect(() => {
    const handleEmailValidation = async () => {
      try {
        if (paramEmail || email) {
          setLoading(false);
          setLoadingBtn(false);

          const emailToValidate = paramEmail ? (await getEmail(paramEmail)).email : email;
          setEmailToShow(emailToValidate);
          dispatch(
            setValidateAccountParameters({
              email: getEmailLowerCase('' + emailToValidate),
              tokenExpirationTime: 60,
              newLogin: true,
            })
          );

          // Remove email from the URL
          const url = new URL(window.location.toString());
          url.searchParams.delete('email');
          window.history.replaceState(null, '', url);
        } else {
          goToLogin();
        }
      } catch (error) {
        console.error('Error validating email:', error);
        goToLogin();
      }
    };

    handleEmailValidation();
  }, [paramEmail, email]);

  if (loading) {
    return <CircularProgressCustom />;
  } else {
    return (
      <>
        <HeaderLogo />

        <div>
          <div className={classesLogin.containerIcon}>
            <ArrowLeftOutlinedIcon className={classes.icon} onClick={goToLogin} />
            <Typography className={classes.title}>{'Ingresar contraseña'}</Typography>
          </div>
          <div className={classes.subTitleContainer}>
            <Typography className={classes.subTitle}>
              {`Ingresa la `} <strong>contraseña temporal</strong> {` que enviamos a tu correo`}{' '}
              {emailToShow && <strong>{` ${maskEmail(emailToShow)}`}</strong>} para continuar.
            </Typography>
            <Typography className={classes.subTitlenewLogin}>
              Si no la encuentras, revisa tu carpeta de spam o comunícate con soporte.
            </Typography>
          </div>
          <div className={classes.containerForm}>
            <form onSubmit={handleSubmit(onSubmit, onError)} autoComplete="off">
              <input className={classes.fakeStyle} type="text" name="fakeuser" />
              <input className={classes.fakeStyle} type="password" name="fakepassword" />
              <Grid
                container
                rowSpacing={2}
                columnSpacing={5}
                sx={{ display: 'grid !important', gap: '32px', marginTop: '32px !important' }}
              >
                <Grid item xs={12} sx={{ padding: '0px 0px 0px 40px !important' }}>
                  <InputTextController
                    sx={{ margin: '0px !important' }}
                    name={'password'}
                    label={'Contraseña'}
                    placeholder={'Ingresa contraseña temporal'}
                    control={control}
                    type={'password'}
                    minLength={1}
                    required={true}
                    showPassword={showPassword}
                    handleClickShowPassword={handleClickShowPassword}
                    helperText={alertMessage ? alertMessage : undefined}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    error={alertMessage ? true : false}
                  />
                </Grid>
                <Grid item xs={12} sx={{ padding: '0px 0px 0px 40px !important' }}>
                  <LoadingButton
                    sx={{ margin: '0px !important' }}
                    loading={loadingBtn}
                    disabled={loadingBtn}
                    className={loadingBtn ? classesLogin.buttonDisabled : classesLogin.button}
                    variant="contained"
                    size="medium"
                    type="submit"
                    onSubmit={handleSubmit(onSubmit, onError)}
                  >
                    {'Iniciar sesión'}
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>

        <div className={classes.containerForm}>
          <Divider className={classes.divider} />

          <div className={classes.containerCreateUser}>
            <HelpSection withPaddingBottom={false} />
          </div>
        </div>

        <DevelopedBySection />
      </>
    );
  }
};

export default ValidateTemporalPasswordForm;
