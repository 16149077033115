import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';

import API, { getPortalBasicInformation, getPortalInformation } from '../../api/api';
import ErrorPage from '../../components/ErrorPage';
import Spinner from '../../components/Spinner';
import {
  CONTACT_CHANNEL_PHONE_DEFAULT,
  LOGIN_IMAGE,
  LOGO_DEFAULT,
  LOGO_HORIZONTAL_DEFAULT,
  URL_LOCALHOST_DEFAULT,
} from '../../constants/constants';
import { getDefaultI18nextInstance } from '../../i18n/index';
import { BusinessUnitParams } from '../../models/BusinessUnitParamsModel';
import { PortalConfigParamsModel } from '../../models/PortalConfigParamsModel';
import { getTokenAuthentication, isAuthenticate } from '../../util/commons';

type ContextType = Partial<BusinessUnitParams>;
const BusinessUnitParamsContext = createContext<ContextType>({});
export default BusinessUnitParamsContext;

export const BusinessUnitParamsProvider: React.FC = ({ children }) => {
  const { error, isLoading, businessUnitParams, portalConfigParams } = useBusinessUnitParams();
  const [titleState, setTitleState] = useState<string>('Cargando...');
  const currentPortalConfigParams = portalConfigParams
    ? portalConfigParams
    : new PortalConfigParamsModel('', '', '', '', '', [], false);

  if (error) {
    const errorMessage = axios.isAxiosError(error)
      ? error.response?.data?.message || error.message
      : error.message;

    return <ErrorPage errorMessage={errorMessage} />;
  }

  if (isLoading) return <Spinner />;

  return (
    <BusinessUnitParamsContext.Provider
      value={
        {
          ...businessUnitParams,
          titleState,
          setTitleState,
          portalConfigParams: currentPortalConfigParams,
        } || {}
      }
    >
      {children}
    </BusinessUnitParamsContext.Provider>
  );
};

const setI18nTranslations = (file?: string) => {
  if (file != undefined) {
    getDefaultI18nextInstance({
      path: file,
    });
  } else {
    getDefaultI18nextInstance({
      path: 'https://devfsmdiag.blob.core.windows.net/uatfsm-portal-public/e48d1918-f0ba-4b05-8aa9-2d8ad7a4dc32-1677155619800.json',
    });
  }
};

function useBusinessUnitParams() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error>();
  const [businessUnitParams, setBusinessUnitParams] = useState<BusinessUnitParams>();
  const [portalConfigParams, setPortalConfigParams] = useState<any>();
  const url =
    window.location.origin === URL_LOCALHOST_DEFAULT
      ? process.env.REACT_APP_URL_DEFAULT
      : window.location.origin;
  const contextUrlHome =
    window.location.origin === URL_LOCALHOST_DEFAULT
      ? process.env.REACT_APP_URL_DEFAULT
      : window.location.protocol + '//' + window.location.host;

  const getPortalInfo = async (businessUnitUUID: any) => {
    if (isAuthenticate(businessUnitUUID)) {
      const token = getTokenAuthentication(businessUnitUUID);

      getPortalInformation(businessUnitUUID, token).then((response) => {
        if (response.data.selfManagementPortalConfig?.loginTypeId == 2) {
          response.data.selfManagementPortalConfig.newLogin = true;
        } else {
          response.data.selfManagementPortalConfig.newLogin = false;
        }
        setPortalConfigParams(response?.data?.selfManagementPortalConfig);
      });
    } else {
      getPortalBasicInformation(businessUnitUUID).then((response) => {
        if (response.data.selfManagementPortalConfig?.loginTypeId == 2) {
          response.data.selfManagementPortalConfig.newLogin = true;
        } else {
          response.data.selfManagementPortalConfig.newLogin = false;
        }
        setPortalConfigParams(response?.data?.selfManagementPortalConfig);
      });
    }
  };

  useEffect(() => {
    if (portalConfigParams?.businessUnitUUID) {
      setIsLoading(false);
    }
  }, [portalConfigParams]);

  useEffect(() => {
    setIsLoading(true);
    getBusinessUnitParams(url!)
      .then(async (response) => {
        await getPortalInfo(response.businessUnitUUID);
        const payload = {
          ...response,
          contactChannelPhone: response.contactChannelPhone
            ? response.contactChannelPhone
            : CONTACT_CHANNEL_PHONE_DEFAULT,
          logoUrl: response && response.fileUrlLogoBo ? response.fileUrlLogoBo : LOGO_DEFAULT,
          logoHorizontalUrl:
            response && response.logo_horizontal_url
              ? response.logo_horizontal_url
              : LOGO_HORIZONTAL_DEFAULT,
          logoWithoutAuthentication:
            response && response.fileUrl ? response.fileUrl : LOGO_HORIZONTAL_DEFAULT,
          name: response && response.name ? response.name : '',
          contextUrl: url,
          contextUrlHome: contextUrlHome,
          customerPortalCoverURL: response.customerPortalCoverURL
            ? response.customerPortalCoverURL
            : LOGIN_IMAGE,
        };
        setBusinessUnitParams(payload);
      })
      .catch(setError);
  }, []);

  useEffect(() => {
    if (!businessUnitParams) return;
    const { fileFaviconUrl, name } = businessUnitParams;
    updateTitle(name);
    updateOrCreateFavIcon(fileFaviconUrl);
    setI18nTranslations(businessUnitParams?.filename);
  }, [businessUnitParams]);

  return { businessUnitParams, portalConfigParams, isLoading, error };
}

async function getBusinessUnitParams(url: string) {
  const params = { url: url };
  const res = await API.get('/business-unit/info-by-portal-url', {
    params,
  });
  return res.data;
}

function updateTitle(title: string) {
  document.title = title || 'Customer portal';
}

function updateOrCreateFavIcon(url: string) {
  const currentLink: HTMLLinkElement | null = document.querySelector("link[rel*='icon']");
  if (currentLink) return (currentLink.href = url);
  const newLink = document.createElement('link');
  newLink.type = 'image/x-icon';
  newLink.rel = 'shortcut icon';
  newLink.href = url;
  document.getElementsByTagName('head')[0].appendChild(newLink);
}
